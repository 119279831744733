// src/App.tsx
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Terms from "./Pages/Terms";
import { ABOUTUS, HOME, TERMS } from "./Utilities/constantLinks";
import Aboutus from "./Pages/Aboutus";
import ScrollToTop from "./components/ScrollToTop";

const App: React.FC = () => {
  return (
    <BrowserRouter>
    <ScrollToTop /> 
      <Routes>
        <Route path={HOME} element={<Home />} />
        <Route path={TERMS} element={<Terms />} />
        <Route path={ABOUTUS} element={<Aboutus />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
